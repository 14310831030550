import React from 'react';
import { useParams } from 'react-router-dom';
import { Header as ChaosHeader } from '@chaos/layout';
import '@chaos/layout/dist/index.css';

import { TARGET_ORIGIN, LANGUAGES } from './constants';

import '../styles/header.css';

const Header = () => {
  const { lg } = useParams();

  const onMouseEnter = () => {
    window.parent.postMessage('mouseenter', TARGET_ORIGIN);
  };

  const onMouseLeave = () => {
    window.parent.postMessage('mouseleave', TARGET_ORIGIN);
  };

  const currentLg = LANGUAGES.find((l) => l.zendeskLabel === lg);

  return (
    <div className="wrapper" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <ChaosHeader
        url={`${window.WEBSITE_URL}/${currentLg.chaosCode}/api/header`}
        currentLanguage={currentLg.chaosCode}
        languages={LANGUAGES.map((l) => ({ code: l.zendeskCode, label: l.chaosLabel, link: '#' }))}
        onChangeLanguage={(languageCode) => {
          window.parent.location = `${TARGET_ORIGIN}hc/${languageCode}`;
        }}
      />
    </div>
  );
};

export default Header;
