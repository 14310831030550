import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Footer from './components/Footer';
import Header from './components/Header';

import './styles/globals.css';

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/:lg/header">
        <Header />
      </Route>
      <Route exact path="/:lg/footer">
        <Footer />
      </Route>
    </Switch>
  </Router>
);

export default App;
