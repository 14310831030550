import React from 'react';
import { useParams } from 'react-router-dom';
import { MidFooter as ChaosFooter } from '@chaos/layout';
import '@chaos/layout/dist/index.css';

import { LANGUAGES } from './constants';

const Footer = () => {
  const { lg } = useParams();
  const currentLg = LANGUAGES.find((l) => l.zendeskLabel === lg);

  return <ChaosFooter url={`${window.WEBSITE_URL}/${currentLg.chaosCode}/api/footer`} />;
};

export default Footer;
