export const TARGET_ORIGIN = 'https://support.chaos.com/';

export const LANGUAGES = [
  { zendeskCode: 'en-us', chaosCode: 'en', chaosLabel: 'English', zendeskLabel: 'English (US)' },
  { zendeskCode: 'es', chaosCode: 'es', chaosLabel: 'Español', zendeskLabel: 'Español' },
  { zendeskCode: 'pt-br', chaosCode: 'pt', chaosLabel: 'Português (Brasil)', zendeskLabel: 'Português do Brasil' },
  { zendeskCode: 'zh-cn', chaosCode: 'cn', chaosLabel: '简体中文', zendeskLabel: '简体中文' },
  { zendeskCode: 'ko', chaosCode: 'kr', chaosLabel: '한국어', zendeskLabel: '한국어' },
  { zendeskCode: 'ja', chaosCode: 'en', chaosLabel: '日本語', zendeskLabel: '日本語' },
];
